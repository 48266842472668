<template>
    <v-app>
        <v-container class="fadein" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="6">
                    <div class="success-animation">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                    </div>
                    <v-alert type="success">
                       Votre demande de paiement en 4x par carte bancaire a bien été acceptée. Votre commande a été enregistrée et vous recevrez une confirmation par e-mail.
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>


<script>
import xhr from "../plugins/axios";

// TODO: Replace the following with your app's Firebase project configuration


export default {
    name: "Success",
    data() {
        return {
            audioSrc: "",
            snackbar: false,
            loading: false,
            valid: true,
            email: "",
            password: "",
            nameRules: [
                (v) => !!v || "Mot de passe obligatoire",
                (v) =>
                    (v && v.length >= 8) ||
                    "Mot de passe doit faire au moins 8 caractères",
            ],
            emailRules: [
                (v) => !!v || "E-mail obligatoire",
                (v) => /.+@.+\..+/.test(v) || "E-mail doit être valide",
            ],
        };
    },
    created() {
        window.scrollTo(0, 0);
    },
    async mounted() {
    },
    methods: {
        
    },
};
</script>

<style>
.success-animation {
    margin: 50px auto;
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;

    margin: 0 auto;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}</style>